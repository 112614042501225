<template>
  <b-nav tabs>
    <b-nav-item :to="{name: this.$routeName.TRAINER_EDIT}" exact exact-active-class="active">Основная информация</b-nav-item>
    <b-nav-item :to="{name: this.$routeName.TRAINER_EDIT_PLACE}" exact exact-active-class="active">Площадки</b-nav-item>
    <b-nav-item :to="{name: this.$routeName.TRAINER_VIEW_TARIFF}" exact exact-active-class="active">Тарифы</b-nav-item>
    <b-nav-item :to="{name: this.$routeName.TRAINER_VIEW_TIMETABLE}" exact exact-active-class="active">Расписание</b-nav-item>
  </b-nav>
</template>


<script>

export default {
  name: 'TrainerEditTab'
};

</script>
