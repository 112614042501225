<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Редактировать тренера</h1>
      </div>
      <div class="page-header__ell">
        <!--<b-form-checkbox v-model="checked" name="check-button" size="lg" switch>-->
        <!--  Включить / отключить-->
        <!--</b-form-checkbox>-->
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div>
        <TrainerEditTab/>
      </div>
      <div v-if="add">
        <div class="add-link" style="margin-top: 15px;">
          {{add}}
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect width="28" height="28" rx="14" fill="#E54C4C"/><path d="M14 6.5v15M21.5 14h-15" stroke="#161616" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
      </div>
    </div>

    <slot/>

  </div>
</template>

<script>

import TrainerEditTab from "@page/Trainer/_component/TrainerEditTab";

export default {
  name: "TrainerEditTemplate",
  components: {
    TrainerEditTab,
  },
  props: {
    add: { type: String, default: '' }
  }
};

</script>

<style lang="scss" scoped>

</style>
