<template>
  <div class="card-tariff" :class="{'card-tariff--active': is_active }">
    <div class="card-tariff__body">
      <div class="card-tariff__head">
        <div class="card-tariff__status"></div>
        <div>
          <span class="card-tariff__name">{{ name }}</span>
          <span v-if="prise" class="card-tariff__prise">От {{ prise }}  &#8381;</span>
        </div>
      </div>
      <div class="card-tariff__text">{{ text }}</div>
      <div class="card-tariff__tags">
        <template v-for="item in tags">
          <div class="card-tariff__tags-ell" :key="item.index">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'card-tariff__tags-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.5 8.25l3.168 3.25L13 5" stroke="#CAF12E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
            {{ item }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardTariff",
  props: {
    is_active: Boolean,
    name: String,
    prise: [String, Number],
    text: String,
    tags: Array,
  }
};
</script>

<style lang="scss" scoped>

// card-tariff
.card-tariff {
  position: relative;
  padding: 12px 16px;
  background-color: var(--br-block-prime);
  border-radius: 8px;
  cursor: pointer;
  .card-tariff__body {
    .card-tariff__head {
      display: flex;
      margin-bottom: 4px;
    }
    .card-tariff__name {
      margin-right: 12px;
      color: var(--text-prime);
      font-size: 17px;
    }
    .card-tariff__prise {
      color: var(--color-prime);
      font-weight: 500;
      font-size: 14px;
      white-space: nowrap;
    }
    .card-tariff__text {
      margin-bottom: 16px;
      color: var(--text-descr);
      font-size: 14px;
    }
  }
  .card-tariff__tags {
    .card-tariff__tags-ell {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      color: var(--text-sub);
      font-size: 14px;
      line-height: 1;
      .card-tariff__tags-icon {
        flex-shrink: 0;
        width: 16px;
        height: auto;
        margin-bottom: 2px;
        margin-right: 6px;
      }
    }
  }
  .card-tariff__status {
    width: 8px;
    height: 8px;
    margin-top: 6px;
    margin-right: 6px;
    border-radius: 50%;
    background-color: var(--color-red);
  }
  &.card-tariff--active {
    .card-tariff__status {
      background-color: var(--color-green);
    }
  }
}

</style>
