<template>
  <TrainerEditTemplate
    :clubId="clubId"
  >
    <div class="row">
      <template v-for="(item, index) in tariffList">
        <div class="col-4 mb-sm" :key="index">
          <router-link :to="{name: $routeName.TARIFF_EDIT, params: {tariffId: item.id} }">
            <CardTariff
              :name="item.name"
              :prise="item.prise"
              :text="item.text"
              :tags="item.tags"
              :is_active="item.is_active"
            />
          </router-link>
        </div>
      </template>
    </div>
    <b-pagination
      v-if="pageCount > pageLimit"
      v-model="pageCurrent"
      :total-rows="pageCount"
      :per-page="pageLimit"
      @change="paginationChange"

      aria-controls="table"
      align="right"
      last-class="hidden"
      first-class="hidden"
      first-number
      last-number
    ></b-pagination>
  </TrainerEditTemplate>
</template>

<script>

import TrainerEditTemplate from './_component/TrainerEditTemplate';
import CardTariff from "@component/Module/CardTariff";

export default {
  components: {
    TrainerEditTemplate,
    CardTariff,
  },
  props: {
    trainerId: {
      type: [String, Number],
      required: true
    }
  },
  data(){
    return {
      pageCurrent: 1,
      pageLimit: 12,
      pageCount: null,
      tariff: null,
      tariffList: [],
      ageList: [],
      typeList: [],
    };
  },
  methods:{
    load(){
      // TODO: fix request
      RequestManager.Tariff.getTariffList({
        // clubId: 1,
        trainerId: this.trainerId,
        limit : this.pageLimit,
        page  : this.pageCurrent,
      }).then( (itemList) => {
        this.pageCount = itemList.count;
        this.tariffList = itemList.results.map((item) => {
          return {
            id        : item.id,
            name      : item.name,
            prise     : item.price,
            text      : `${this.tariffGetType(item.type)} • ${this.tariffGetAge(item.age)} • Тренировки: ${item.length}`,
            tags      : [item.tag.tag], //TODO fix
            is_active : item.is_active,
          };
        });
      });
    },
    tariffGetType(val) {
      return this.typeList.find(item => item.key === val).value;
    },
    tariffGetAge(val) {
      return this.ageList.find(item => item.key === val).value;
    },
    paginationChange(val) {
      this.pageCurrent = val;
      this.load();
    }
  },
  created() {
    RequestManager.Reference.getClubAgeList({}).then((list) => {
      this.ageList = list;
    });
    RequestManager.Reference.getTrainGroupTypeList({}).then((list) => {
      this.typeList = list;
    });
    this.load();
  }
};
</script>

<style lang="scss" scoped>
// fix row
.row {
  margin-left: -8px;
  margin-right: -8px;
  .col-4, .col-8 {
    padding: 0 8px;
  }
}
</style>

